/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from '../images/favicon_io/favicon.ico'
import { useStaticQuery, graphql } from "gatsby"
 
const SEO = ({ description, lang, meta, title, keywords, ogImage, ogImageWidth, ogImageHeight }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaImage = ogImage || 'https://i.imgur.com/giC4CiJ.jpg'
  const metaImageWidth = ogImageWidth || 400
  const metaImageHeight = ogImageHeight || 200
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
      { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
      ]}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        { name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:image:width`,
          content: metaImageWidth,
        },
        {
          property: `og:image:height`,
          content: metaImageHeight,
        },
        {
          property: `og:url`,
          content: `https://www.funovus.com`,
        },
        {
          property: `og:site_name`,
          content: `funovus.com`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:url`,
          content: `https://www.funovus.com`
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO