import React from "react"
import { Link } from "gatsby"

function Footer({ page }){
    return(        
        <footer className="footer">
            <div>
                <Link to="/">
                    <img className="logo" src={page === "home" ? "https://i.imgur.com/HfqMLmA.png" : "https://i.imgur.com/lSgwByg.png"} alt="Funovus" width="80" height="80" />
                </Link>
                <h2 className="companyName">FUNOVUS</h2>
                <span>©2024</span>
            </div>
            <div>
            <img className="badge" src="https://i.imgur.com/orzFHip.png" width="200" height="72" alt="Google for Startup 2022" />
            </div>
        </footer>
    )
}
export default Footer